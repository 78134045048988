import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import '../../assets/scss/demo.css';

import { Link } from "gatsby"


const Referances = () => (
  <Layout>
    <SEO
      title="Başarı Hikayeleri"
      description=" Dijital pazarlama alanındaki uzmanlığımızı sergilediğimiz başarı hikayelerimize göz atmak için tıklayın!"
    />
    <div>
      <div class="container">
        <div className='mt-5 mb-3'>
          <h2 className='text-black'><strong>Gerçek Başarı Hikayeleri</strong></h2>
          <p>Sunduğumuz hizmetler ve özel stratejilerin yansımalarını deneyimleyen müşterilerimizden bazılarına
            ait <br></br> istatistikler.</p>
        </div>
        <div class="row">
        <div class="col-xs-12 text-white">
            <div class="position-relative herotwo" style={{ backgroundImage: "url('/images/toyota-case-study.webp')" }} loading="lazy">
              <div className='mask z-2 position-absolute '></div>
              <img src="/images/toyota-logo.webp" alt="toyota logo" class="position-absolute top-0 z-3 m-5 testing" style={{ width: "150px" }} loading="lazy"></img>
              <div class="position-absolute bottom-0 z-3 m-5">
                <h3 className="float">Otomotiv Şirketi</h3>
                <div className='d-flex gap-3 my-3 '>
                  <div className='border-start ps-2'>
                    <h2 className="float">+%35</h2>
                    <p className='small-text mt-3 news'>Organik Trafikte Kullanıcı.<br/> ve Oturum Sayısı Artışı</p>
                  </div>
                  <div className='border-start ps-2'>
                    <h2 className="float">+50.000</h2>
                    <p className='small-text mt-3'>Yeni Kelimede Görünürlük</p>
                  </div>
                  <div className='border-start ps-2'>
                    <h2 className="float">+%195</h2>
                    <p className='small-text mt-3'>Form Dönüşümü Artışı</p>
                  </div>
                  <div className='border-start ps-2'>
                    <h2 className="float">+12</h2>
                    <p className='small-text mt-3'>Ay Çalışma Dönemi</p>
                  </div>
                </div>
                
                { /*   <p className='d-flex justify-content-center align-items-center gap-1 news'>
                  <img src="/images/arrow-icon.png " alt="" class="" style={{ width: "25px" }} loading="lazy"></img>
                  Çalışmanın detaylarına göz atın</p>*/}
              </div>
            </div>
          </div>
          <div class="col-xs-12 text-white testing">
            <div class="position-relative herotwo" style={{ backgroundImage: "url('/images/stm-case-study.webp')" }} loading="lazy">
              <div className='mask z-2 position-absolute '></div>
              <img src="/images/stm-logo.webp" loading="lazy" alt="stm logo" class="position-absolute top-0 z-3 m-5 testings" style={{ width: "150px" }}></img>
              <div class="position-absolute bottom-0 z-3 m-5">
                <h3 className="float">Global Savunma Sanayi Şirketi</h3>
                <div className='d-flex gap-3 my-3 testing'>
                  <div className='border-start ps-2'>
                    <h2 className="float">+%22</h2>
                    <p className='small-text mt-3 news'>Organik <br/>Trafik Artışı</p>
                  </div>
                  <div className='border-start ps-2'>
                    <h2 className="float">+%27.7</h2>
                    <p className='small-text mt-3'>Takipçi Kazanımı</p>
                  </div>
                  <div className='border-start ps-2'>
                    <h2 className="float">+6 AY</h2>
                    <p className='small-text mt-3'>Çalışma dönemi</p>
                  </div>
                  
                </div>
                <Link to="/basari-hikayeleri/stm/">
                <p className='d-flex justify-content-center align-items-center gap-1 news'>
                  <img src="/images/arrow-icon.png" alt="" class="" style={{ width: "25px" }} loading="lazy"></img>
                  Çalışmanın detaylarına göz atın</p></Link>
              </div>
            </div>
          </div>
          <div class="col-xs-6">
            <div class="position-relative img-2 bg-black my-4" style={{ backgroundImage: "url('/images/tcdd-case-study.webp')" }} loading="lazy">
              <img src="/images/logo2.png" alt="" class="position-absolute top-0 z-3 m-4 testing" style={{ width: "100px" }}></img>
              <div className='mask z-2 position-absolute'></div>
              <div class="position-absolute bottom-0 z-3 m-4">
                <h3 className="float">Ulusal Taşımacılık Firması</h3>
                <div className='d-flex gap-3 my-3 '>
                  <div className='border-start ps-2'>
                    <h2 className="float">+%24</h2>
                    <p className='small-text mt-3'>Dönüşüm Oranıartışı</p>
                  </div>
                  <div className='border-start ps-2'>
                    <h2 className="float">+%125</h2>
                    <p className='small-text mt-3'>Organik Trafik Artışı</p>
                  </div>
                </div>
                <Link to="/basari-hikayeleri/tcdd-tasimacilik/">
                <p className='d-flex justify-content-center text-white align-items-center gap-1'>
                  <img src="/images/arrow-icon.png" alt="" class="" style={{ width: "25px" }}></img>
                  Çalışmanın detaylarına göz atın
                </p></Link>
              </div>
            </div>
            <div class="position-relative img-3 my-4" style={{ backgroundImage: "url('/images/stm-case-study-two.webp')" }} loading="lazy">
              <div className='mask z-2 position-absolute'></div>
              <div class="position-absolute bottom-0 z-3 m-4">
                <h3 className="float">Global Savunma Sanayi Şirketi</h3>
                <div className='d-flex gap-3 my-3 '>
                  <div className='border-start ps-2'>
                    <h2 className="float test text s">+(-35.48%)</h2>
                    <p className='small-text mt-3 testing text'>PPC I AVG. CPC</p>
                  </div>
                  <div className='border-start ps-2'>
                    <h2 className="float test">+87.31%</h2>
                    <p className='small-text mt-3'>Gösterim</p>
                  </div>
                  <div className='border-start ps-2'>
                    <h2 className="float test">+125.71%</h2>
                    <p className='small-text mt-3'>Tıklama</p>
                  </div>
                  <div className='border-start ps-2'>
                    <h2 className="float test">+12 AY</h2>
                    <p className='small-text mt-3'>Çalışma Dönemi</p>
                  </div>
                </div>
               { /*<p className='d-flex justify-content-center text-white align-items-center gap-1 testing'>
                  <img src="/images/arrow-icon.png" alt="" class="" style={{ width: "25px" }} loading="lazy" ></img>
                  Çalışmanın detaylarına göz atın</p>*/}
              </div>
              <img src="/images/stm-logo.webp" alt="" class="position-absolute bottom-0 end-0 z-3 m-4 testing" style={{ width: "120px" }}></img>
            </div>
          </div>
          <div class="col-xs-6">
            <div class="position-relative img-3 my-4" style={{ backgroundImage: "url('/images/liva-case-study.webp')" }} loading="lazy">
              <div className='mask z-2 position-absolute'></div>
              <img src="/images/liva-case-study-logo.webp" alt="" class="position-absolute top-0 end-0 z-3 m-4 testing" style={{ width: "150px", left:0 }}></img>
              <div class="position-absolute bottom-0 z-3 m-4">
                <h3 className="float">Yerel Pastacılık Firması</h3>
                <div className='d-flex gap-3 my-3 '>
                  <div className='border-start ps-2'>
                    <h2 className="float">+%15</h2>
                    <p className='small-text mt-3 lh-sm'>E-Ticaret<br></br>
                    Dönüşüm Oranı</p>
                  </div>
                  <div className='border-start ps-2'>
                    <h2 className="float">+4,500</h2>
                    <p className='small-text mt-3 lh-sm'>'den Fazla Aylık <br></br>
                      sipariş Adeti</p>
                  </div>
                </div>
                <Link to="/basari-hikayeleri/liva-pastacilik-case-study/">
                <p className='d-flex justify-content-center text-white align-items-center gap-1'>
                  <img src="/images/arrow-icon.png" alt="" class="" style={{ width: "25px" }}></img>
                  Çalışmanın detaylarına göz atın</p></Link>
              </div>
            </div>
            <div class="position-relative img-2 bg-black my-4" style={{ backgroundImage: "url('/images/mocaco-case-study.webp')" }} loading="lazy">
              <img src="/images/mocaco-logo-case.webp" alt="" class="position-absolute top-0 z-3 m-5 testing" style={{ width: "100px" }} loading="lazy"></img>
              <div className='mask z-2 position-absolute '></div>
              <div class="position-absolute bottom-0 z-3 m-5">
                <h3 className="float">Lokal Kafe ve Restaurant İşletmesi</h3>
                <div className='d-flex gap-3 my-3 '>
                  <div className='border-start ps-2'>
                    <h2 className="float moca">+%25</h2>
                    <p className='small-text mt-3 loda'>Organik<br/>Trafik Artışı</p>
                  </div>
                  <div className='border-start ps-2'>
                    <h2 className="float moca">+%21</h2>
                    <p className='small-text mt-3 loda'>Toplam<br/>Click</p>
                  </div>
                  <div className='border-start ps-2'>
                    <h2 className="float moca">+%112</h2>
                    <p className='small-text mt-3 loda'>Gösterim</p>
                  </div>
                  <div className='border-start ps-2'>
                    <h2 className="float moca">+6 AY</h2>
                    <p className='small-text mt-3 loda'>Çalışma<br/>Dönemi</p>
                  </div>
                </div>
                
                { /* <p className='d-flex text-white align-items-center gap-1'>
                  <img src="/images/arrow-icon.png" alt="" style={{ width: "25px" }}></img>
                  Çalışmanın detaylarına göz atın</p>*/}
              </div>
            </div>
            
          </div>
          <div class="col-xs-12 text-white">
            <div class="position-relative herotwo" style={{ backgroundImage: "url('/images/lova-case-study.webp')" }} loading="lazy">
              <div className='mask z-2 position-absolute '></div>
              <img src="/images/loda-case-study-logo.webp" alt="toyota logo" class="position-absolute top-0 z-3 m-5 testingls" style={{ width: "150px" }} loading="lazy"></img>
              <div class="position-absolute bottom-0 z-3 m-5">
                <h3 className="float">Lüks Mobilya & Dekorasyon</h3>
                <div className='d-flex gap-3 my-3 '>
                  <div className='border-start ps-2'>
                    <h2 className="float">+%239</h2>
                    <p className='small-text mt-3 loda'>Paid Trafik <br/>Artışı</p>
                  </div>
                  <div className='border-start ps-2'>
                    <h2 className="float">+%230</h2>
                    <p className='small-text mt-3 loda'>Lead Gen.<br/>Increase</p>
                  </div>
                  <div className='border-start ps-2'>
                    <h2 className="float">6</h2>
                    <p className='small-text mt-3 loda'>Campaign<br/>Targeting<br/> Location</p>
                  </div>
                  <div className='border-start ps-2'>
                    <h2 className="float testings">+6 AY</h2>
                    <p className='small-text mt-3 loda'>Çalışma Dönemi</p>
                  </div>
                </div>
                
                { /*  <p className='d-flex justify-content-center align-items-center gap-1 news'>
                  <img src="/images/arrow-icon.png" alt="" class="" style={{ width: "25px" }}></img>
                  Çalışmanın detaylarına göz atın</p>*/}
              </div>
            </div>
          </div>
          <div class="col-xs-6">
            <div class="position-relative img-2 bg-black my-4" style={{ backgroundImage: "url('/images/cam-hotel-case-study.webp')" }} loading="lazy">
              <img src="/images/cam-hotel-case-logo.webp" alt="" class="position-absolute top-0 z-3 m-4 testing save" style={{ width: "100px" }} loading="lazy"></img>
              <div className='mask z-2 position-absolute'></div>
              <div class="position-absolute bottom-0 z-3 m-4">
                <h3 className="float">Termal Resort Spa & Convertion Center</h3>
                <div className='d-flex gap-3 my-3 '>
                  <div className='border-start ps-2'>
                    <h2 className="float">+%150</h2>
                    <p className='small-text mt-3'>Organik Trafik Artışı</p>
                  </div>
                  <div className='border-start ps-2'>
                    <h2 className="float">+12 AY</h2>
                    <p className='small-text mt-3'>Çalışma Dönemi</p>
                  </div>
                </div>
               
                { /*  <p className='d-flex justify-content-center text-white align-items-center gap-1'>
                  <img src="/images/arrow-icon.png" alt="" class="" style={{ width: "25px" }}></img>
                  Çalışmanın detaylarına göz atın
                </p>*/}
              </div>
            </div>
            <div class="position-relative img-3 my-4" style={{ backgroundImage: "url('/images/unwomen-case-study.webp')" }} loading="lazy">
              <div className='mask z-2 position-absolute'></div>
              <div class="position-absolute bottom-0 z-3 m-4">
                <h3 className="float">Uluslararası Organizasyon</h3>
                <div className='d-flex gap-3 my-3 '>
                  <div className='border-start ps-2'>
                    <h2 className="float">17M</h2>
                    <p className='small-text mt-3'>Kitle Erişimi</p>
                  </div>
                  <div className='border-start ps-2'>
                    <h2 className="float">220k</h2>
                    <p className='small-text mt-3'>Proje katılımı</p>
                  </div>
                </div>
                <Link to="/basari-hikayeleri/un-women/">
                <p className='d-flex justify-content-center text-white align-items-center gap-1 testing'>
                  <img src="/images/arrow-icon.png" alt="" class="" style={{ width: "25px" }} loading="lazy"></img>
                  Çalışmanın detaylarına göz atın</p></Link>
              </div>
              <img src="/images/unwoman-case-logo.webp" alt="" class="position-absolute bottom-0 end-0 z-3 m-4 testing un" style={{ width: "110px" }} loading="lazy"></img>
            </div>
          </div>
          <div class="col-xs-6">
            <div class="position-relative img-3 my-4" style={{ backgroundImage: "url('/images/liva-two-case-study.webp')" }} loading="lazy">
              <div className='mask z-2 position-absolute'></div>
              <img src="/images/liva-case-study-logo.webp" alt="" class="position-absolute top-0 end-0 z-3 m-4 testing" style={{ width: "150px", left:0}} loading="lazy"></img>
              <div class="position-absolute bottom-0 z-3 m-4">
                <h3 className="float">Yerel Pastacılık Firması</h3>
                <div className='d-flex gap-3 my-3 '>
                  <div className='border-start ps-2'>
                    <h2 className="float">%5.79</h2>
                    <p className='small-text mt-3 lh-sm'>E-Ticaret<br></br>
                    organik trafik</p>
                  </div>
                  <div className='border-start ps-2'>
                    <h2 className="float">+90k</h2>
                    <p className='small-text mt-3 lh-sm'>Aylık edinilen<br></br>
                      sipariş Adeti</p>
                  </div>
                  <div className='border-start ps-2'>
                    <h2 className="float">+4,500</h2>
                    <p className='small-text mt-3 lh-sm'>'den fazla aylık<br></br>
                      sipariş Adeti</p>
                  </div>
                  <div className='border-start ps-2'>
                    <h2 className="float">+%61</h2>
                    <p className='small-text mt-3 lh-sm'>Aylık Tekrarlı<br></br>
                    Sipariş Oranı</p>
                  </div>
                </div>
                <Link to="/basari-hikayeleri/liva-pastacilik/">
                <p className='d-flex justify-content-center text-white align-items-center gap-1'>
                  <img src="/images/arrow-icon.png" alt="" class="" style={{ width: "25px" }} loading="lazy"></img>
                  Çalışmanın detaylarına göz atın</p></Link>
              </div>
            </div>
            <div class="position-relative img-2 bg-black my-4" style={{ backgroundImage: "url('/images/mocaco-case-two.webp')" }} loading="lazy">
              <img src="/images/mocaco-logo-case.webp" alt="" class="position-absolute top-0 z-3 m-5 testing" style={{ width: "100px" }} loading="lazy"></img>
              <div className='mask z-2 position-absolute '></div>
              <div class="position-absolute bottom-0 z-3 m-5">
                <h3 className="float">Lokal Kafe ve Restaurant İşletmesi</h3>
                <div className='d-flex gap-3 my-3 '>
                  <div className='border-start ps-2'>
                    <h2 className="float moc">+%22</h2>
                    <p className='small-text mt-3 moc'>Organik Trafik Artışı</p>
                  </div>
                  <div className='border-start ps-2'>
                    <h2 className="float moc">+ %27.7</h2>
                    <p className='small-text mt-3 moc'>Takipçi Kazanımı</p>
                  </div>
                  <div className='border-start ps-2'>
                    <h2 className="float moc">+ %27.7</h2>
                    <p className='small-text mt-3 moc'>Takipçi Kazanımı</p>
                  </div>
                  <div className='border-start ps-2'>
                    <h2 className="float moc">+ %27.7</h2>
                    <p className='small-text mt-3 moc'>Takipçi Kazanımı</p>
                  </div>
                </div>
                <Link to="/basari-hikayeleri/mocaco-coffee/">
                <p className='d-flex text-white align-items-center gap-1'>
                  <img src="/images/arrow-icon.png" alt="" style={{ width: "25px" }} loading="lazy"></img>
                  Çalışmanın detaylarına göz atın</p></Link>
              </div>
            </div>
            
          </div>
        </div>
        <div class="row">
<div class="col-xs-6">
<div class="position-relative img-2 bg-black my-4" style={{ backgroundImage: "url('/images/uks-case-study.webp')" }} loading="lazy">
              <img src="/images/uks-yapi-case-logo.webp" alt="" class="position-absolute top-0 z-3 m-4 testing save" style={{ width: "120px" }} loading="lazy"></img>
              <div className='mask z-2 position-absolute'></div>
              <div class="position-absolute bottom-0 z-3 m-4">
                <h3 className="float moc">Su Yalıtım ve Yapı Kimyasalları Firması</h3>
                <div className='d-flex gap-3 my-3 '>
                  <div className='border-start ps-2'>
                    <h2 className="float moc">+%26,85</h2>
                    <p className='small-text mt-3 moc'>Organik<br/>Trafik Artışı</p>
                  </div>
                  <div className='border-start ps-2'>
                    <h2 className="float moc"> +%160</h2>
                    <p className='small-text mt-3 moc'>Çalışma<br/>Dönemi</p>
                  </div>
                  <div className='border-start ps-2'>
                    <h2 className="float moc">+%403</h2>
                    <p className='small-text mt-3 moc'>Gösterim</p>
                  </div>
                  <div className='border-start ps-2'>
                    <h2 className="float moc">+6 AY</h2>
                    <p className='small-text mt-3 moc'>Çalışma<br/> Dönemi</p>
                  </div>
                </div>
                { /*  <p className='d-flex justify-content-center text-white align-items-center gap-1'>
                  <img src="/images/arrow-icon.png" alt="" class="" style={{ width: "25px" }} loading="lazy"></img>
                  Çalışmanın detaylarına göz atın
                </p>*/}
              </div>
            </div>
</div>
<div class="col-xs-6">
<div class="position-relative img-2 bg-black my-4" style={{ backgroundImage: "url('/images/ayba-metal-case-study.webp')" }} loading="lazy">
              <img src="/images/ayba-metal-case-logo.webp" alt="" class="position-absolute top-0 z-3 m-5 testing ayba" style={{ width: "160px" }} loading="lazy"></img>
              <div className='mask z-2 position-absolute '></div>
              <div class="position-absolute bottom-0 z-3 m-5">
                <h3 className="float">Alçıpan ve Prefabrik Yapı Üreticisi</h3>
                <div className='d-flex gap-3 my-3 '>
                  <div className='border-start ps-2'>
                    <h2 className="float moc">+%104</h2>
                    <p className='small-text mt-3 moc'>Organik <br/>Trafik Artışı</p>
                  </div>
                  <div className='border-start ps-2'>
                    <h2 className="float moc">+ %84</h2>
                    <p className='small-text mt-3 moc'>Toplam<br/>Click</p>
                  </div>
                  <div className='border-start ps-2'>
                    <h2 className="float moc">+%257.33</h2>
                    <p className='small-text mt-3 moc'>Gösterim</p>
                  </div>
                  <div className='border-start ps-2'>
                    <h2 className="float moc">+6 AY</h2>
                    <p className='small-text mt-3 moc'>Çalışma<br/>Dönemi</p>
                  </div>
                </div>
                { /*  <p className='d-flex text-white align-items-center gap-1'>
                  <img src="/images/arrow-icon.png" alt="" style={{ width: "25px" }}></img>
                  Çalışmanın detaylarına göz atın</p>*/}
              </div>
            </div>
</div>
</div> 
<div class="row">
<div class="col-xs-6">
            <div class="position-relative img-2 bg-black my-4" style={{ backgroundImage: "url('/images/yolcu-360-case-study.webp')" }} loading="lazy">
              <img src="/images/yolcu-360-case-logo.webp" alt="" class="position-absolute top-0 z-3 m-4 testing save" style={{ width: "150px" }} loading="lazy"></img>
              <div className='mask z-2 position-absolute'></div>
              <div class="position-absolute bottom-0 z-3 m-4">
                <h3 className="float">Ulusal Araç Kiralama Platformu</h3>
                <div className='d-flex gap-3 my-3 '>
                  <div className='border-start ps-2'>
                    <h2 className="float">+%24</h2>
                    <p className='small-text mt-3'>Dönüşüm Oranı</p>
                  </div>
                  <div className='border-start ps-2'>
                    <h2 className="float">-%12</h2>
                    <p className='small-text mt-3 gain'>Tıklama Başı<br/>Maliyet Azalması</p>
                  </div>
                </div>
                <Link to="/basari-hikayeleri/yolcu360/">
                <p className='d-flex justify-content-center text-white align-items-center gap-1'>
                  <img src="/images/arrow-icon.png" alt="" class="" style={{ width: "25px" }} loading="lazy"></img>
                  Çalışmanın detaylarına göz atın
                </p></Link>
              </div>
            </div>
            <div class="position-relative img-3 my-4" style={{ backgroundImage: "url('/images/milli-piyango-case-study.webp')" }} loading="lazy">
              <div className='mask z-2 position-absolute'></div>
              <div class="position-absolute bottom-0 z-3 m-4">
                <h3 className="float">Ulusal Çekiliş Platformu</h3>
                <div className='d-flex gap-3 my-3 '>
                  <div className='border-start ps-2'>
                    <h2 className="float">+125k</h2>
                    <p className='small-text mt-3 gain'>Web Oturumlarında<br/>Kullanıcı Artışı</p>
                  </div>
                  <div className='border-start ps-2'>
                    <h2 className="float">+133m</h2>
                    <p className='small-text mt-3 gain'>Reklam<br/>Gösterimi</p>
                  </div>
                </div>
                <Link to="/basari-hikayeleri/piyango-sepeti/">
                <p className='d-flex justify-content-center text-white align-items-center gap-1 testing'>
                  <img src="/images/arrow-icon.png" alt="" class="" style={{ width: "25px" }} loading="lazy"></img>
                  Çalışmanın detaylarına göz atın</p></Link>
              </div>
              <img src="/images/milli-piyango-case-study-logo.webp" alt="" class="position-absolute bottom-0 end-0 z-3 m-4 testing gain" style={{ width: "130px" }} loading="lazy"></img>
            </div>
          </div>
          <div class="col-xs-6">
            <div class="position-relative img-3 my-4" style={{ backgroundImage: "url('/images/pakmaya-case-study.webp')" }} loading="lazy">
              <div className='mask z-2 position-absolute'></div>
              <img src="/images/pakmaya-case-logo.webp" alt="" class="position-absolute top-0 end-0 z-3 m-4 pakmaya" style={{ width: "150px", left:0}}></img>
              <div class="position-absolute bottom-0 z-3 m-4">
                <h3 className="float">Global Gıda Firması</h3>
                <div className='d-flex gap-3 my-3 '>
                  <div className='border-start ps-2'>
                    <h2 className="float">10x</h2>
                    <p className='small-text mt-3 lh-sm'>Organik Trafik<br></br>
                    Artışı</p>
                  </div>
                  <div className='border-start ps-2'>
                    <h2 className="float">+%126</h2>
                    <p className='small-text mt-3 lh-sm'>Erişilen Kitlenin<br></br>
                    Arttırılması</p>
                  </div>
                </div>
                <Link to="/basari-hikayeleri/pakmaya/">
                <p className='d-flex justify-content-center text-white align-items-center gap-1'>
                  <img src="/images/arrow-icon.png" alt="" class="" style={{ width: "25px" }} loading="lazy"></img>
                  Çalışmanın detaylarına göz atın</p></Link>
              </div>
            </div>
            <div class="position-relative img-2 bg-black my-4" style={{ backgroundImage: "url('/images/robesnmore-case-study.webp')" }} loading="lazy">
              <img src="/images/robesNmore-white-logo-case.webp" alt="" class="position-absolute top-0 z-3 m-5 testing robes" style={{ width: "180px" }} loading="lazy"></img>
              <div className='mask z-2 position-absolute '></div>
              <div class="position-absolute bottom-0 z-3 m-5">
                <h3 className="float">Havlu ve Bornoz Ürünleri Toptan Satış Tedarikçisi</h3>
                <div className='d-flex gap-3 my-3 '>
                  <div className='border-start ps-2'>
                    <h2 className="float moc">+%132</h2>
                    <p className='small-text mt-3 moc'>Organik <br/>Trafik Artışı</p>
                  </div>
                  <div className='border-start ps-2'>
                    <h2 className="float moc">+44%</h2>
                    <p className='small-text mt-3 moc'>Yeni <br/>Kullanıcı</p>
                  </div>
                  <div className='border-start ps-2'>
                    <h2 className="float moc">+22%</h2>
                    <p className='small-text mt-3 moc'>E-Ticaret<br/>alışveriş Dönüşümü</p>
                  </div>
                  <div className='border-start ps-2'>
                    <h2 className="float moc">+12 AY</h2>
                    <p className='small-text mt-3 moc'>Çalışma<br/>Dönemi</p>
                  </div>
                </div>
                <Link to="/basari-hikayeleri/robesnmore/">
                <p className='d-flex text-white align-items-center gap-1'>
                  <img src="/images/arrow-icon.png" alt="" style={{ width: "25px" }} loading="lazy"></img>
                  Çalışmanın detaylarına göz atın</p></Link>
              </div>
            </div>
            
          </div>         
    </div>
    
    <div class="row">
    <div class="col-xs-12 text-white">
            <div class="position-relative herotwo" style={{ backgroundImage: "url('/images/enerjisa-case-study.webp')" }} loading="lazy">
              <div className='mask z-2 position-absolute '></div>
              <img src="/images/enerjisa-case-logo.webp" alt="enerjisa logo" class="position-absolute top-0 z-3 m-5 testing ener" style={{ width: "150px" }} loading="lazy"></img>
              <div class="position-absolute bottom-0 z-3 m-5">
                <h3 className="float">Ulusal Enerji Firması</h3>
                <div className='d-flex gap-3 my-3 '>
                  <div className='border-start ps-2'>
                    <h2 className="float">+8.2k</h2>
                    <p className='small-text mt-3 moc'>"Ayedaş"<br/>Anahtar Kelime</p>
                  </div>
                  <div className='border-start ps-2'>
                    <h2 className="float">+6.5K</h2>
                    <p className='small-text mt-3 moc'>"Başkent Edaş”<br/>Anahtar Kelime</p>
                  </div>
                </div>
                <Link to="/basari-hikayeleri/enerjisa/">
                <p className='d-flex justify-content-center align-items-center gap-1 news'>
                  <img src="/images/arrow-icon.png" alt="" class="" style={{ width: "25px" }} loading="lazy"></img>
                  Çalışmanın detaylarına göz atın</p></Link>
              </div>
            </div>
          </div>
          <div class="col-xs-6">
          <div class="position-relative img-2 bg-black my-4" style={{ backgroundImage: "url('/images/tanoto-case-study.webp')" }} loading="lazy">
              <img src="/images/tanoto-case-logo.webp" alt="" class="position-absolute top-0 z-3 m-4 testing tan" style={{ width: "150px" }} loading="lazy"></img>
              <div className='mask z-2 position-absolute'></div>
              <div class="position-absolute bottom-0 z-3 m-4">
                <h3 className="float">Yerel Otomotiv Markası</h3>
                <div className='d-flex gap-3 my-3 '>
                  <div className='border-start ps-2'>
                    <h2 className="float">+750k</h2>
                    <p className='small-text mt-3 moc'>İzlenme<br/>erişimi</p>
                  </div>
                  <div className='border-start ps-2'>
                    <h2 className="float">+%30</h2>
                    <p className='small-text mt-3 moc'>Takipçi<br/>Artışı</p>
                  </div>
                  <div className='border-start ps-2'>
                    <h2 className="float">+24 Ay</h2>
                    <p className='small-text mt-3 moc'>Çalışma<br/>dönemi</p>
                  </div>
                </div>
                <Link to="/basari-hikayeleri/tanoto/">
                <p className='d-flex justify-content-center text-white align-items-center gap-1'>
                  <img src="/images/arrow-icon.png" alt="" class="" style={{ width: "25px" }} loading="lazy"></img>
                  Çalışmanın detaylarına göz atın
                </p></Link>
              </div>
            </div>
</div>
<div class="col-xs-6">
<div class="position-relative img-2 bg-black my-4" style={{ backgroundImage: "url('/images/kuruyemis-borsa-case-study.webp')" }} loading="lazy">
              <img src="/images/kuruyemis_borsasi-logo.webp" alt="" class="position-absolute top-0 z-3 m-5 testing ayba" style={{ width: "160px" }} loading="lazy"></img>
              <div className='mask z-2 position-absolute '></div>
              <div class="position-absolute bottom-0 z-3 m-5">
                <h3 className="float">Online Kuruyemiş Markası</h3>
                <div className='d-flex gap-3 my-3 '>
                  <div className='border-start ps-2'>
                    <h2 className="float">+%186</h2>
                    <p className='small-text mt-3 moc'>Dönüşüm<br/>Oranı</p>
                  </div>
                  <div className='border-start ps-2'>
                    <h2 className="float">+%34</h2>
                    <p className='small-text mt-3 moc'>Yıllık Ciro<br/>Artışı</p>
                  </div>
                  <div className='border-start ps-2'>
                    <h2 className="float">+6 Ay</h2>
                    <p className='small-text mt-3 moc'>Çalışma<br/>Dönemi</p>
                  </div>
                  
                </div>
                <Link to="/basari-hikayeleri/kuruyemis-borsasi/">
                <p className='d-flex text-white align-items-center gap-1'>
                  <img src="/images/arrow-icon.png" alt="" style={{ width: "25px" }} loading="lazy"></img>
                  Çalışmanın detaylarına göz atın</p></Link>
              </div>
            </div>
</div>
          <div class="col-xs-6">
            <div class="position-relative img-2 bg-black my-4" style={{ backgroundImage: "url('/images/colorant-case-study.webp')" }} loading="lazy">
              <img src="/images/sefa-stone-case.webp" alt="" class="position-absolute top-0 z-3 m-4 testing sefas" style={{ width: "100px" }} loading="lazy"></img>
              <div className='mask z-2 position-absolute'></div>
              <div class="position-absolute bottom-0 z-3 m-4">
                <h3 className="float">Global Perakende Firması</h3>
                <div className='d-flex gap-3 my-3 '>
                  <div className='border-start ps-2'>
                    <h2 className="float">%46</h2>
                    <p className='small-text mt-3 moc'>Daha Fazla<br/>Müşteri Dönüşümü</p>
                  </div>
                  <div className='border-start ps-2'>
                    <h2 className="float">%27</h2>
                    <p className='small-text mt-3 moc'>2 Yıllık Organik<br/>Trafik Artışı</p>
                  </div>
                </div>
                <Link to="/basari-hikayeleri/sefa-stone/">
                <p className='d-flex justify-content-center text-white align-items-center gap-1 testing'>
                  <img src="/images/arrow-icon.png" alt="" class="" style={{ width: "25px" }} loading="lazy"></img>
                  Çalışmanın detaylarına göz atın</p></Link>
              </div>
            </div>
            <div class="position-relative img-3 my-4" style={{ backgroundImage: "url('/images/arcadium-case-study.webp')" }} loading="lazy">
              <div className='mask z-2 position-absolute'></div>
              <div class="position-absolute bottom-0 z-3 m-4">
                <h3 className="float">Yerel Alışveriş Merkezi</h3>
                <div className='d-flex gap-3 my-3 '>
                <div className='border-start ps-2'>
                    <h2 className="float">%172</h2>
                    <p className='small-text mt-3 moc'>2 Yıl İçerisindeki<br/>Ciro Artışı</p>
                  </div>
                  <div className='border-start ps-2'>
                    <h2 className="float">%150</h2>
                    <p className='small-text mt-3 moc'>2 Yıllık Organik<br/>Trafik Artışı</p>
                  </div>
                  <div className='border-start ps-2'>
                    <h2 className="float">+24 Ay</h2>
                    <p className='small-text mt-3 moc'>Çalışma<br/>Dönemi</p>
                  </div>
                </div>
                <Link to="/basari-hikayeleri/arcadium/">
                <p className='d-flex justify-content-center text-white align-items-center gap-1 testing'>
                  <img src="/images/arrow-icon.png" alt="" class="" style={{ width: "25px" }} loading="lazy"></img>
                  Çalışmanın detaylarına göz atın</p></Link>
              </div>
              <img src="/images/Arcadium-logo.webp" alt="" class="position-absolute bottom-0 end-0 z-3 m-4 testing arc" style={{ width: "90px" }} loading="lazy"></img>
            </div>
          </div>
          <div class="col-xs-6">
            <div class="position-relative img-3 my-4" style={{ backgroundImage: "url('/images/fulla-moda-case-study.webp')" }} loading="lazy">
              <div className='mask z-2 position-absolute'></div>
              <img src="/images/fullamoda-case-logo.webp" alt="" class="position-absolute top-0 end-0 z-3 m-4 testing" style={{ width: "150px", left:0 }} loading="lazy"></img>
              <div class="position-absolute bottom-0 z-3 m-4">
                <h3 className="float">Ulusal Tekstil Markası</h3>
                <div className='d-flex gap-3 my-3 '>
                  <div className='border-start ps-2'>
                    <h2 className="float">+%114.70</h2>
                    <p className='small-text mt-3 moc'>Dönüşüm<br></br>
                    Oranı Artışı</p>
                  </div>
                  <div className='border-start ps-2'>
                    <h2 className="float">+%279.20</h2>
                    <p className='small-text mt-3 moc'>Ciro<br></br>
                    Artışı</p>
                  </div>
                  
                </div>
                <Link to="/basari-hikayeleri/fullamoda/">
                <p className='d-flex justify-content-center text-white align-items-center gap-1'>
                  <img src="/images/arrow-icon.png" alt="" class="" style={{ width: "25px" }} loading="lazy"></img>
                  Çalışmanın detaylarına göz atın</p></Link>
              </div>
            </div>
            <div class="position-relative img-2 bg-black my-4" style={{ backgroundImage: "url('/images/kentpark-case-study.webp')" }} loading="lazy">
              <img src="/images/kentpark-case-logo.webp" alt="" class="position-absolute top-0 z-3 m-5 testing kent" style={{ width: "130px" }} loading="lazy"></img>
              <div className='mask z-2 position-absolute '></div>
              <div class="position-absolute bottom-0 z-3 m-5">
                <h3 className="float">Yerel Alışveriş Merkezi</h3>
                <div className='d-flex gap-3 my-3 '>
                  <div className='border-start ps-2'>
                    <h2 className="float">%125</h2>
                    <p className='small-text mt-3 moc'>Instagram<br/>Takipçi Artışı</p>
                  </div>
                  <div className='border-start ps-2'>
                    <h2 className="float">+6m</h2>
                    <p className='small-text mt-3 moc'>Aylık Erişilen<br/>Kullanıcı Sayısı</p>
                  </div>
                  
                </div>
                <Link to="/basari-hikayeleri/kentpark/">
                <p className='d-flex text-white align-items-center gap-1'>
                  <img src="/images/arrow-icon.png" alt="" style={{ width: "25px" }} loading="lazy"></img>
                  Çalışmanın detaylarına göz atın</p></Link>
              </div>
            </div>
            
          </div>
    </div>
    <div class="row">
<div class="col-xs-6">
<div class="position-relative img-2 bg-black my-4" style={{ backgroundImage: "url('/images/ecanta-case.webp')" }} loading="lazy">
              <img src="/images/ecanta_logo-case.webp" alt="" class="position-absolute top-0 z-3 m-4 testing save" style={{ width: "120px" }} loading="lazy"></img>
              <div className='mask z-2 position-absolute'></div>
              <div class="position-absolute bottom-0 z-3 m-4">
                <h3 className="float ">Ulusal Online Aksesuar Platformu</h3>
                <div className='d-flex gap-3 my-3 '>
                  <div className='border-start ps-2'>
                    <h2 className="float ">+28k</h2>
                    <p className='small-text mt-3 moc'>Organik<br/>Trafik Artışı</p>
                  </div>
                  <div className='border-start ps-2'>
                    <h2 className="float "> +%26</h2>
                    <p className='small-text mt-3 moc'>Reklam Maliyetinin<br/>Düşürülmesi</p>
                  </div>
                </div>
                <Link to="/basari-hikayeleri/ecanta/">
                <p className='d-flex justify-content-center text-white align-items-center gap-1'>
                  <img src="/images/arrow-icon.png" alt="" class="" style={{ width: "25px" }} loading="lazy"></img>
                  Çalışmanın detaylarına göz atın
                </p></Link>
              </div>
            </div>
</div>
<div class="col-xs-6">
<div class="position-relative img-2 bg-black my-4" style={{ backgroundImage: "url('/images/villa-pre-case-study.webp')" }} loading="lazy">
              <img src="/images/villa-pre-logo-case.webp" alt="" class="position-absolute top-0 z-3 m-5 testing ayba" style={{ width: "160px" }} loading="lazy"></img>
              <div className='mask z-2 position-absolute '></div>
              <div class="position-absolute bottom-0 z-3 m-5">
                <h3 className="float">Ulusal Prefabrik Firması</h3>
                <div className='d-flex gap-3 my-3 '>
                  <div className='border-start ps-2'>
                    <h2 className="float">+%80</h2>
                    <p className='small-text mt-3 moc'>Tıklama<br/>Oranı Artışı</p>
                  </div>
                  <div className='border-start ps-2'>
                    <h2 className="float">+%109</h2>
                    <p className='small-text mt-3 moc'>Dönüşüm Oranı<br/>Kazanımı</p>
                  </div>
                  <div className='border-start ps-2'>
                    <h2 className="float">+24 Ay</h2>
                    <p className='small-text mt-3 moc'>Çalışma<br/>Dönemi</p>
                  </div>
                  
                </div>
                <Link to="/basari-hikayeleri/villa-yapi/">
                <p className='d-flex text-white align-items-center gap-1'>
                  <img src="/images/arrow-icon.png" alt="" style={{ width: "25px" }} loading="lazy"></img>
                  Çalışmanın detaylarına göz atın</p></Link>
              </div>
            </div>
</div>
</div> 


      </div>
    </div>

   

   
  </Layout>
)

export default Referances
